<template>
  <div class="profile-item">
    <v-btn :class="isChecked ? 'profile-item__check--checked' : 'profile-item__check'" @click="check()" icon>
      <v-icon class="profile-item__check__icon">{{ isChecked ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
    </v-btn>
    <v-img class="profile-item__favicon" :src="'https://www.google.com/s2/favicons?domain=' + collection.url" />
    <p class="profile-item__name">{{ collection.collectionName }}</p>
    <confirm-change ref="change" :name="collection.collectionName" />
    <confirm-warning ref="warning" />
  </div>
</template>

<script>
import ConfirmChange from '@/components/profile/confirm/category/change'
import ConfirmWarning from '@/components/profile/confirm/category/warning'

export default {
  components: { ConfirmChange, ConfirmWarning },
  props: {
    // 登録されたQRコード情報
    collection: {
      type: Object,
      required: true
    },
    // 活性中のカテゴリー名
    active: {
      type: String,
      required: true
    },
    // 選択済みかどうか
    isChecked: {
      type: Boolean,
      required: true
    },
    // 登録中のコレクション情報
    // { カテゴリー名: [ {}, ... ], ... }
    collections: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @return {Object[]} 他のカテゴリーに登録されているコレクション一覧
     */
    otherCollections () {
      let otherCollections = []
      Object.keys(this.collections).forEach(category => {
        if (category !== this.active) otherCollections = otherCollections.concat(this.collections[category])
      })
      return otherCollections
    },
    /**
     * @return {Boolean} 別のカテゴリーで登録済みかどうかの判定
     */
    isRegistrated () {
      return this.otherCollections.some(other => other.cid === this.collection.cid)
    }
  },
  methods: {
    /**
     * 指定のカテゴリーにリンクを登録する
     */
    check () {
      // カテゴリーが選択されてない場合、カテゴリー選択を促す
      if (!this.active) {
        this.$refs.warning.open()
        return
      }

      // 他のカテゴリーに登録済みの場合、変更できない旨を警告する
      if (this.isRegistrated) {
        this.$refs.change.open()
        return
      }

      // チェックの追加・削除を行う
      const isChecked = !this.isChecked
      this.$emit('set-colleciton', this.collection.cid, isChecked ? this.collection : null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.profile-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 34px 0;
  &__check {
    margin-right: 20px;
    &.v-btn--icon.v-size--default {
      min-width: auto;
      height: auto;
    }
    &.v-btn--icon.v-size--default .v-icon {
      color: $gray_color;
    }
    &--checked {
      @extend .profile-item__check;
      &.v-btn--icon.v-size--default .v-icon {
        color: $orange_color;
      }
    }
    &__icon {
      font-family: $material-outlined;
      &.v-icon.v-icon {
        font-size: 3rem;
      }
    }
  }
  &__favicon {
    flex: initial;
    width: 16px;
    height: 16px;
    margin-right: 20px;
  }
  &__name {
    flex: 1;
    margin: 0;
    font-size: 1.4rem;
    word-break: break-all;
  }
}
</style>
