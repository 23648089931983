<template>
  <v-dialog class="profile-form-category" v-model="dialog">
    <v-card class="profile-form-category__area">
      <v-card-text class="profile-form-category__area__text">
        まずは、登録するカテゴリーを選択してください。
      </v-card-text>
      <v-btn text class="profile-form-category__area__btn" @click="dialog = false">閉じる</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      // ダイアログを開いているか
      dialog: false
    }
  },
  methods: {
    /**
     * ダイアログの表示
     */
    open () {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.profile-form-category {
  &__area {
    width: 100%;
    padding: 20px 20px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__text {
      margin: 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black_color;
      }
    }
    &__btn {
      width: 40%;
      margin: 20px 5px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $orange_color;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

// vuetify用
.v-dialog {
  width: 73%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
