<template>
  <div class="profile-edit-link" v-if="!isProcessing">
    <link-navigation class="profile-edit-link__nav"
                     :categories="candidateCategories" @set-active="setActive" @add-category="addCategory" />
    <v-btn class="profile-edit-link__delte" v-if="links.length > 0"
           @click="$refs.delete.open()" text>
      <v-icon class="profile-edit-link__delte__icon">delete</v-icon>カテゴリーの削除
    </v-btn>
    <link-item class="profile-edit-link__item" v-for="collection in collections" :key="collection.cid"
               :collection="collection" :active="active || ''"
               :isChecked="isChecked({ category: active, cid: collection.cid })" :collections="checkedCollections"
               @set-colleciton="setColleciton" />
    <v-btn class="profile-edit-link__submit" depressed
           :disabled="!hasChanged" @click="save()">保存</v-btn>
    <confirm-delete ref="delete" @delete-category="deleteCategory" />
  </div>
</template>

<script>
import LinkNavigation from '@/components/profile/navigation'
import ConfirmDelete from '@/components/profile/confirm/category/delete'
import LinkItem from '@/components/profile/item'

export default {
  name: 'profile_edit_link',
  components: { LinkNavigation, ConfirmDelete, LinkItem },
  data () {
    return {
      // 選択中のカテゴリー
      active: null,
      // カテゴリーの候補
      candidateCategories: [],
      // 登録済みのコレクション情報
      // { カテゴリー名: [ {}, ... ], ... }
      registeredCollections: {},
      // チェックしたコレクション情報
      // { カテゴリー名: [ {}, ... ], ... }
      checkedCollections: {},
      // 新規追加したQRコード情報
      // [ { catgory: カテゴリー名, cid: コレクションID, object: コレクション }, ... ]
      addCollections: [],
      // 削除したQRコード情報
      // [ { catgory: カテゴリー名, cid: コレクションID, object: コレクション }, ... ]
      deleteCollections: []
    }
  },
  async mounted () {
    // リンク情報の取得
    if (this.links.length === 0) await this.$store.dispatch('links/getLinks', this.uid)

    // カテゴリー候補の代入（ディープコピー）
    this.candidateCategories = JSON.parse(JSON.stringify(this.links))

    // cidに基づくコレクション情報を取得
    const linkPromises = []
    for (const link of this.links) {
      if (!this.registeredCollections[link.name]) this.registeredCollections[link.name] = []
      if (!this.checkedCollections[link.name]) this.checkedCollections[link.name] = []
      for (const cid of link.cids) {
        if (!this.collection(cid)) linkPromises.push(this.$store.dispatch('collections/getCollection', cid))
      }
    }
    await Promise.all(linkPromises)

    // cidに基づくコレクション情報を格納
    for (const link of this.links) {
      for (const cid of link.cids) {
        const collection = this.collection(cid)
        this.registeredCollections[link.name].push(collection)
        this.checkedCollections[link.name].push(collection)
      }
    }

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {String} ユーザー名
     */
    uname () {
      return this.$store.getters['uidUname/uname'](this.uid) || this.uid
    },
    /**
     * @return {Object[]} リンク情報（表示名順にソート）
     */
    links () {
      return this.$store.getters['links/links'](this.uid)
    },
    /**
     * @param {String} cid コレクションID
     * @return {Object} コレクション情報
     */
    collection () {
      return cid => {
        return this.$store.getters['collections/collection'](cid)
      }
    },
    /**
     * @return {Object[]} 登録済みのQRコード
     */
    collections () {
      return this.$store.getters['collections/collections']
    },
    /**
     * @param {String} payload.catetgory カテゴリー名
     * @param {String} payload.cid コレクションID
     * @return {Boolean} チェック済みかどうか
     */
    isChecked () {
      return payload => {
        return this.checkedCollections[payload.category] ?
          this.checkedCollections[payload.category].some(collection => collection.cid === payload.cid) :
          false
      }
    },
    /**
     * @return {Boolean} リンク集に修正があったかどうか
     */
    hasChanged () {
      return this.addCollections.length > 0 || this.deleteCollections.length > 0
    }
  },
  methods: {
    /**
     * カテゴリーの選択
     * @param {String} name カテゴリー名
     */
    setActive (name) {
      this.active = name
    },
    /**
     * カテゴリーの追加
     * @param {String} name カテゴリー名
     */
    addCategory (name) {
      this.candidateCategories.unshift({
        name: name,
        lid: null
      })
    },
    /**
     * コレクション情報の追加 or 削除
     * @param {String} cid コレクションのドキュメントID
     * @param {Object} collection コレクション情報（削除の時はnull）
     */
    setColleciton (cid, collection) {
      // 新規追加か削除か
      const isAdd = !!collection

      // 追加・削除情報にすでに存在すれば、それを一度削除
      this.addCollections = this.addCollections.filter(collection => !(this.active === collection.category && collection.cid === cid))
      this.deleteCollections = this.deleteCollections.filter(collection => !(this.active === collection.category && collection.cid === cid))

      // 新規追加の情報かの確認
      const collections = this.registeredCollections[this.active] || []
      const isNewCollection = collections.every(collection => collection.cid !== cid)
      const data = {
        category: this.active,
        cid: cid,
        object: collection
      }

      // 追加・削除情報の格納
      if (isAdd) {
        if (!this.checkedCollections[this.active]) this.checkedCollections[this.active] = []
        this.checkedCollections[this.active].push(collection)
        if (isNewCollection) this.addCollections.push(data)
      } else {
        this.checkedCollections[this.active] = this.checkedCollections[this.active].filter(checked => checked.cid !== cid)
        if (!isNewCollection) this.deleteCollections.push(data)
      }
    },
    /**
     * カテゴリーの削除
     * @param {String} lid リンクID
     * @param {String} name カテゴリー名
     */
    deleteCategory (lid, name) {
      this.candidateCategories = this.candidateCategories.filter(category => category.lid !== lid)

      // 削除したカテゴリーに紐づく情報をリセット
      delete this.checkedCollections[name]
      if (this.active === name) this.active = null
    },
    /**
     * リンク情報の編集内容を保存
     */
    async save () {
      this.$store.commit('setSubmitting', true)

      // 追加情報からリンク情報のみを抽出
      const links = this.addCollections.filter((elem1, index, array) => {
        return array.findIndex(elem2 => {
          return elem1.category === elem2.category
        }) === index
      }).map(collection => {
        const lid = this.links.every(link => link.name !== collection.category) ? null :
          this.links.find(link => link.name === collection.category).lid
        return {
          lid: lid,
          name: collection.category
        }
      })

      const createPromises = []
      links.forEach(link =>{
        if (!link.lid) {
          const params = {
            name: link.name,
            uid: this.uid,
            cids: [],
            createdAt: new Date(),
            updatedAt: new Date()
          }
          createPromises.push(this.$store.dispatch('links/createLink', { uid: this.uid, params: params }))
        }
      })

      const newLids = await Promise.all(createPromises)

      const updatePromises = []

      // ユーザー情報が持つlidの一覧を更新
      const lids = this.user.lids ? this.user.lids.concat(newLids) : newLids
      updatePromises.push(this.$store.dispatch('users/updateUser', { uid: this.uid, params: { lids: lids, updatedAt: new Date() } }))

      // リンクの追加と削除の実施
      this.links.forEach(link => {
        const deleteCIDs = []
        this.deleteCollections.forEach(collection => {
          if (collection.category === link.name) deleteCIDs.push(collection.cid)
        })

        const cids = link.cids.filter(cid => !deleteCIDs.includes(cid))

        this.addCollections.forEach(collection => {
          if (collection.category === link.name) cids.push(collection.cid)
        })

        const params = { cids: cids, updatedAt: new Date() }

        updatePromises.push(this.$store.dispatch('links/updateLink', { uid: this.uid, lid: link.lid, params: params }))
      })

      await Promise.all(updatePromises)

      // プロフィール画面へ遷移
      this.$store.commit('setTelop', { show: true, msg: 'リンク情報を更新しました', type: 'success' })
      this.$router.push({ name: 'profile', params: { uname: this.uname } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.profile-edit-link {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto $footer_height auto;
  &__delte {
    display: block;
    margin: 10px 0 0 auto;
    color: $dark_gray_color;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 0;
    }
    &__icon {
      font-family: $material-outlined;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__submit {
    position: fixed;
    right: $padding_width;
    bottom: calc(#{$footer_height} + #{$padding_height});
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 15px;
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 6px 23px;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat) {
      background-color: $orange_lighten_color !important;
    }
    &.theme--light.v-btn.v-btn--disabled {
      color: $white_color !important;
    }
  }
}
</style>
