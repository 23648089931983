<template>
  <div class="profile-navigation">
    <v-btn :class="active === category.name ? 'profile-navigation__item--active' : 'profile-navigation__item'"
           v-for="category in categories" :key="category.name" @click="select(category.name)" outlined>
      {{ category.name }}</v-btn>
    <v-btn class="profile-navigation__item--add" @click="$refs.add.open()" outlined>
      <v-icon>add</v-icon>
    </v-btn>
    <link-category ref="add" @add-category="addCategory" />
  </div>
</template>

<script>
import LinkCategory from '@/components/profile/form/category'

export default {
  components: { LinkCategory },
  props: {
    // カテゴリーの候補一覧
    categories: {
      type: Array,
      requred: true
    }
  },
  data () {
    return {
      // 活性中のカテゴリー
      active: null
    }
  },
  methods: {
    /**
     * カテゴリーの選択
     * @param {String} name カテゴリー名
     */
    select (name) {
      this.active = this.active === name ? null : name
      this.$emit('set-active', this.active)
    },
    /**
     * カテゴリーを追加
     * @param {String} name カテゴリー名
     */
    addCategory (name) {
      this.select(name)
      this.$emit('add-category', name)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile-navigation {
  box-sizing: border-box;
  width: 100vw;
  max-width: $max_width;
  padding: 0 $padding_width;
  margin-left: -$padding_width;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  &__item {
    display: inline-block;
    margin-right: 10px;
    text-transform: none;
    vertical-align: middle;
    border: 2px solid $gray_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 30px;
      height: 30px;
      padding: 0 14px;
      font-size: 1.2rem;
      color: $gray_color;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: transparent;
    }
    &--active {
      @extend .profile-navigation__item;
      border: 2px solid $orange_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        color: $white_color;
        background-color: $orange_color;
      }
    }
    &--add {
      @extend .profile-navigation__item;
      border: 2px solid $orange_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        color: $orange_color;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
