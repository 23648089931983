<template>
  <v-dialog class="profile-form-category" v-model="dialog">
    <v-card class="profile-form-category__area">
      <v-card-text class="profile-form-category__area__text">
        削除したいカテゴリーを選択してください。削除すると復元できませんので、ご注意ください。
      </v-card-text>
      <div class="profile-form-category__area__category">
        <v-btn :class="active === link.lid ? 'profile-form-category__area__category__item--active' : 'profile-form-category__area__category__item'"
               v-for="link in links" :key="link.lid" @click="active = link.lid" outlined>{{ link.name }}</v-btn>
      </div>
      <v-btn text class="profile-form-category__area__btn" :disabled="!active" @click="doDelete()">削除</v-btn>
      <v-btn text class="profile-form-category__area__btn" @click="dialog = false">閉じる</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      // ダイアログを開いているか
      dialog: false,
      // 活性中のカテゴリー名
      active: null
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Object[]} リンク情報（表示名順にソート）
     */
    links () {
      return this.$store.getters['links/links'](this.uid)
    }
  },
  methods: {
    /**
     * ダイアログの表示
     */
    open () {
      this.dialog = true
    },
    /**
     * カテゴリーの削除
     */
    async doDelete () {
      this.$store.commit('setSubmitting', true)

      // 削除するリンク情報を取得
      const link = this.links.find(link => link.lid === this.active)
      const params = {
        lids: this.user.lids.filter(lid => lid !== link.lid),
        updatedAt: new Date()
      }

      const promises = []

      // リンク情報の削除
      promises.push(this.$store.dispatch('links/deleteLink', { uid: this.uid, lid: this.active }))
      promises.push(this.$store.dispatch('users/updateUser', { uid: this.uid, params: params }))
      this.$emit('delete-category', this.active, link.name)

      this.$store.commit('setSubmitting', false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.profile-form-category {
  &__area {
    width: 100%;
    padding: 20px 20px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__text {
      margin: 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $black_color;
      }
    }
    &__category {
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      margin-top: 20px;
      margin-left: -20px;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      &__item {
        display: inline-block;
        margin-right: 10px;
        text-transform: none;
        vertical-align: middle;
        border: 2px solid $gray_color;
        border-radius: 10px;
        &.v-btn:not(.v-btn--round).v-size--default {
          min-width: 30px;
          height: 30px;
          padding: 0 14px;
          font-size: 1.2rem;
          color: $gray_color;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-color: transparent;
        }
        &--active {
          @extend .profile-form-category__area__category__item;
          border: 2px solid $orange_color;
          &.v-btn:not(.v-btn--round).v-size--default {
            color: $white_color;
            background-color: $orange_color;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__btn {
      width: 40%;
      margin: 20px 5px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $orange_color;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

// vuetify用
.v-dialog {
  width: 73%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
